import React from "react"
import Layout from "@components/layout";
import FormsParts from "@components/forms-contactform7-2";

// 関数呼び出し
import { SeoContents } from "@components/seo-data.js" // Seo情報設定

const Recruit = () => {
  // Seo情報設定
  const pathName = 'recruit';
  const seoTag = SeoContents({ Path:pathName });

  return (
  <>
    <Layout>
      {seoTag}
      <div> 
        {/* FV */}
        <div className="corp_h1">
          <h1 className="font-semibold ">採用情報</h1>
        </div>
        <div>
          <div className="max-w-xl mx-auto  md:max-w-5xl px-6 ">
            <p className=" mt-[50px]">株式会社Enzineでは、主に金融関連のwebメディアの運営に携わってくださる方を採用しています。<br/>
            ご興味のある方からのご応募、心よりお待ちしております。</p>

            <div className="mb-10  text-left  max-w-xs md:max-w-5xl md:mx-auto md:mt-[30px] mt-[50px]"> 
            <h2 className="pt-2 font-semibold tracking-wide text-2xl md:text-3xl ">Business content</h2>
          </div>
            <p>Webライターとして記事の作成やsnsの運用代行業務に取り組んでいただきます。</p>

            <div className="mb-10   text-left  max-w-xs md:max-w-5xl md:mx-auto md:mt-[30px] mt-[50px]"> 
            <h2 className="pt-2 font-semibold tracking-wide text-2xl md:text-3xl ">Application Requirements</h2>
          </div>



          <div className="bus_flex max-w-xl mx-auto  md:max-w-5xl ">
            <table className="border">
              <tr><td className="p-[20px] border-b text-center bg-[#F7F7F7]">募集職種</td><td className="p-[20px] border-b">ライター及びsnsの運用代行</td></tr>
              <tr><td className="p-[20px] border-b text-center bg-[#F7F7F7]">雇用形態</td><td className="p-[20px] border-b">業務委託契約</td></tr>
              <tr><td className="p-[20px] border-b text-center bg-[#F7F7F7]">応募資格</td><td className="p-[20px] border-b">必須条件<br/>ライターとしての経験 1年以上</td></tr>
              <tr><td className="p-[20px] border-b text-center bg-[#F7F7F7]">報酬</td><td className="p-[20px] border-b">要相談（取り組んでいただける業務内容で要相談）</td></tr>
              <tr><td className="p-[20px] border-b text-center bg-[#F7F7F7]">勤務先</td><td className="p-[20px] border-b">リモートワーク基本</td></tr>
            </table>
          </div>

          <div className="  text-left max-w-xs md:max-w-5xl md:mx-auto md:mt-[30px] mt-[50px]"> 
            <h2 className="mb-10  pt-2 font-semibold tracking-wide text-2xl md:text-3xl ">Contact</h2>
          </div>

          <div className="md:max-w-2xl  mx-auto  pt-5   "> 

            <div className="mb-[100px]">
              <FormsParts/>
            </div>
          </div>
        </div>
      </div>
      </div>
    </Layout> 
  </>
  )
}
export default Recruit